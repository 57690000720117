export const InstanceName = 'Axco Portal';
export const ACTIONS = {
  SET_USER_DATA: 'SET_USER_DATA',
  SET_MY_PRODUCT_DATA: 'SET_MY_PRODUCT_DATA',
  SET_OTHER_PRODUCT_DATA: 'SET_OTHER_PRODUCT_DATA',
  UPDATE_THEME: 'UPDATE_THEME',
};
export const PORTAL_HOME_MENU_ITEM = {
  id: '0000',
  title: 'AXCO Portal',
  url: '/',
  newTab: false,
  secure: null,
  image: `${window.location.origin}${process.env.PUBLIC_URL}/portal-icon/ic_home.svg`,
  customClass: 'portal-home',
  isActive: true,
  desc: '',
  tooltip: '',
};
export const NOT_AUTHORISED =
  'You are not authorised to use this application. Please contact administrator';
export const ERROR_PAGES_ROUTES = ['/notAuthorised', '/notFound', '/error-500'];
export const NEW_PASSWORD_ERROR = `Password must be at least 8 characters in length, 
with a minimum of 1 lower case, 1 upper case and 1 special character`;
export const CONFIRM_PASSWORD_ERROR =
  'The new password you have entered do not match.';
export const OLD_PASSWORD_ERROR = `You can't use your old password as new password.`;
export const TOOLTIP_CONTACT_INFO = `<p>To find out more please contact us on +44 20 7374 5252 or axco@axcoinfo.com</p>`;
