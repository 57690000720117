class AxcoAnalytics {
  dataLayer = (args) => {
    const { dataLayer, analyticsParameters } = window;
    if (dataLayer && analyticsParameters) {
      dataLayer.push({
        ...args,
        userID: analyticsParameters.userID,
        companyName: analyticsParameters.companyName,
        portalId: analyticsParameters.portalId,
      });
    }
  };
}

const AxcoAnalyticsTracker = new AxcoAnalytics();

export default AxcoAnalyticsTracker;
