import React, { Suspense, lazy } from 'react';
import { bool } from 'prop-types';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import LoaderV2 from '@axco/react.library/dist/loader/loader-v2';
import {
  NotFoundPage,
  NotAuthorisedPage,
  TechErrorPage,
} from '@axco/react.library/dist/pages';

import Scrolltotop from '../../helpers/scrollToTop';
import PrivateRoute from './privateRoute';

const Home = withRouter(lazy(() => import('../home')));
const Disclaimer = withRouter(
  lazy(/* istanbul ignore next */ () => import('../disclaimer')),
);
const UserAccount = withRouter(
  lazy(/* istanbul ignore next */ () => import('../userAccount')),
);
const Dashboard = withRouter(lazy(() => import('../powerbi')));

const Routes = ({ isAuthorised, isSsoUser }) => (
  <Suspense fallback={<LoaderV2 />}>
    <Scrolltotop>
      <Switch>
        <PrivateRoute
          exact
          path="/"
          component={Home}
          isAuthorised={isAuthorised}
        />
        <PrivateRoute
          path="/disclaimer"
          component={Disclaimer}
          isAuthorised={isAuthorised}
        />
        {!isSsoUser && (
          <PrivateRoute
            path="/useraccount"
            component={UserAccount}
            isAuthorised={isAuthorised}
          />
        )}
        <PrivateRoute
          path="/data-dashboard"
          component={Dashboard}
          isAuthorised={isAuthorised}
        />
        <Route path="/notFound" component={NotFoundPage} />
        <Route path="/error-500" component={TechErrorPage} />
        <Route path="/notAuthorised" component={NotAuthorisedPage} />
        <Redirect to="/notFound" />
        {!isAuthorised ? <Redirect to="/notAuthorised" /> : null}
      </Switch>
    </Scrolltotop>
  </Suspense>
);

Routes.propTypes = {
  isAuthorised: bool,
  isSsoUser: bool,
};
Routes.defaultProps = {
  isAuthorised: false,
  isSsoUser: false,
};

export default Routes;
