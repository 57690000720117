/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { string, shape, func } from 'prop-types';
import get from 'lodash/get';
import { ToastContainer } from 'react-toastify';

import { Header } from '@axco/react.library/dist/header';
import { Footer } from '@axco/react.library/dist/footer';
import Storage from '@axco/react.library/dist/storage/storage';
import LoaderV2 from '@axco/react.library/dist/loader/loader-v2';
import ApiErrorHandler from '@axco/react.library/dist/shared/apiErrorHandler';
import AuthService from '@axco/react.library/dist/shared/services/auth';
import Redux from '@axco/react.library/dist/shared/stateManagement';
import SessionTimeoutModal from '@axco/react.library/dist/shared/SessionTimeoutModal';

import {
  isMobileView,
  updateUserData,
  updateMyProductData,
  logError,
  updateOtherProductData,
} from './helpers/utility';
import Analytics from './helpers/analytics';
import {
  InstanceName,
  ERROR_PAGES_ROUTES,
  PORTAL_HOME_MENU_ITEM,
  ACTIONS,
} from './constants';
import Routes from './views/shared/routes';
import {
  getPortalDetails,
  getMyProducts,
  getOtherProducts,
  getBranding,
} from './services/portal';

const { useStateValue } = Redux;

const browserStorage = new Storage('localStorage');

const logSession = (name) => {
  try {
    const lastSession = browserStorage.getItem(name);
    const lastLoggedTime = lastSession
      ? new Date(parseInt(lastSession, 10))
      : new Date();
    const currentTime = new Date();
    const minutes = Number.parseInt(
      (Math.abs(currentTime.getTime() - lastLoggedTime.getTime()) /
        (1000 * 60)) %
        60,
      10,
    );
    if (!lastSession || minutes > 15) {
      Analytics.dataLayer({
        event: name,
        product: document.title,
      });
      browserStorage.setItem(name, Date.now());
    }
  } catch (err) {
    // console.log("err", err);
  }
};

const loadGA = (userID, companyName, portalId) => {
  window.analyticsParameters = {
    userID,
    companyName,
    portalId,
  };
  // check if hotjar is available on the page
  if (window.hj) {
    window.hj('identify', userID, {
      companyName,
    });
  }
  logSession('portal_session');
  Analytics.dataLayer({
    event: 'pageLoad',
    product: document.title,
  });
};

const App = ({ history }) => {
  const [{ user, myProducts }, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(true);
  const [appName, setAppName] = useState(InstanceName);
  const [brandingDetails, setBrandingDetails] = useState(null);
  const centerContent = (
    <div className="product-title">
      <h5 className="mb-0">
        {window.location.pathname === '/data-dashboard'
          ? 'Data Dashboards'
          : appName}
      </h5>
    </div>
  );
  const [headerProps, setHeaderProps] = useState({
    menu: [],
    notifications: {},
    helpButton: true,
    centerContent,
    disclaimerCallback: () => {
      history.push('/disclaimer');
    },
    myAccountCallback: () => {
      history.push('/useraccount');
    },
    waffleButtonCallback: (productDetails) => {
      Analytics.dataLayer({
        event: 'productClick',
        product: get(productDetails, 'title'),
        source: 'Waffle Menu',
      });
      history.push('/');
      return true;
    },
    changeMode: true,
    themeColor: get(brandingDetails, 'data.accentColour', null) || user.theme,
    onThemeChange: /* istanbul ignore next */ (theme) => {
      dispatch({
        type: ACTIONS.UPDATE_THEME,
        payload: { theme: theme === '' ? null : theme },
      });
    },
  });

  useEffect(() => {
    setHeaderProps({
      ...headerProps,
      themeColor: get(brandingDetails, 'data.accentColour', null) || user.theme,
    });
  }, [user.theme]);

  useEffect(() => {
    // Manually Checking if user have refreshed the data-dashboard page
    if (history.location.pathname === '/data-dashboard') {
      setHeaderProps((prevState) => ({
        ...prevState,
        centerContent: (
          <div className="product-title">
            <h5 className="mb-0">Data Dashboards</h5>
          </div>
        ),
      }));
    }
    const unlisten = history.listen(({ pathname }) => {
      if (pathname === '/data-dashboard') {
        setHeaderProps((prevState) => ({
          ...prevState,
          centerContent: (
            <div className="product-title">
              <h5 className="mb-0">Data Dashboards</h5>
            </div>
          ),
        }));
        return;
      }
      setHeaderProps((prevState) => ({
        ...prevState,
        centerContent: (
          <div className="product-title">
            <h5 className="mb-0">{InstanceName}</h5>
          </div>
        ),
      }));
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    ApiErrorHandler(history, false);
    async function fetchData() {
      try {
        const result = await AuthService.getUserName();
        updateUserData(result, dispatch);
        Promise.all([getPortalDetails(), getMyProducts(), getBranding()])
          .then(async ([portalDetails, myProductsResult, branding]) => {
            const portalName = get(portalDetails, 'data.name', InstanceName);
            setAppName(portalName);
            updateMyProductData(myProductsResult, dispatch);
            const portalId = get(portalDetails, 'data.id');
            loadGA(
              get(result, 'data.Id'),
              get(result, 'data.CompanyName'),
              portalId,
            );
            const otherProductsResult = await getOtherProducts(portalId);
            updateOtherProductData(otherProductsResult, dispatch);
            setBrandingDetails(
              get(branding, 'data.isEnabled', false) ? branding : null,
            );
            setHeaderProps((hp) => ({
              ...hp,
              ...(get(branding, 'data.isEnabled', false) !== false
                ? {
                    logo: `${process.env.REACT_APP_BASE_ASSET_URL}commonassets/${branding.data.logoImagePath}`,
                    banner: '',
                    themeColor: get(branding, 'data.accentColour', null),
                    textColor: get(branding, 'data.accentColour2', null),
                    cssClass:
                      get(branding, 'data.logoType') === 2 ? 'tall-logo' : '',
                    onLogoClick: () => {
                      const url = get(branding, 'data.sharedUIBannerUrl', null);
                      if (url) {
                        window.open(url);
                      }
                    },
                  }
                : {}),
            }));
            setIsLoading(false);
          })
          .catch((error) => {
            if (get(error, 'response.status', 0) >= 500) {
              setIsLoading(false);
            }
            logError(error);
          });
      } catch (err) {
        if (get(err, 'response.status', 0) >= 500) {
          setIsLoading(false);
        }
        logError(err);
      }
    }
    // If user is accessing error page then do nothing
    /* istanbul ignore if */
    if (ERROR_PAGES_ROUTES.includes(history.location.pathname)) {
      setIsLoading(false);
      return;
    }
    fetchData();
  }, [dispatch, history]);
  const { userFullName, isAuthorised, IsSsoUser } = user;

  return (
    <>
      <ToastContainer />
      {isLoading ? (
        <LoaderV2 />
      ) : (
        <>
          <Header
            {...headerProps}
            userName={userFullName}
            menu={[PORTAL_HOME_MENU_ITEM, ...myProducts]}
            isSsoUser={!!IsSsoUser}
          />
          <div
            className={`container-fluid content ${
              isMobileView() ? /* istanbul ignore next */ 'mobile-view' : ''
            }`}
          >
            <Routes isAuthorised={isAuthorised} isSsoUser={!!IsSsoUser} />
          </div>
          <SessionTimeoutModal
            apiHandler={AuthService.getUserName}
            sessionTimeInMinutes={60}
          />
          <Footer />
        </>
      )}
    </>
  );
};

App.propTypes = {
  history: shape({
    push: func.isRequired,
    location: shape({
      pathname: string.isRequired,
    }),
  }).isRequired,
};

export default withRouter(App);
