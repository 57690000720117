import { LogError } from '@axco/react.library/dist/shared/services/log';

import {
  ACTIONS,
  NEW_PASSWORD_ERROR,
  CONFIRM_PASSWORD_ERROR,
  OLD_PASSWORD_ERROR,
} from '../constants';

export const isMobileView = /* istanbul ignore next */ () =>
  window.innerWidth < 992;

export const getQueryString = (field, url) => {
  const href = url || /* istanbul ignore next */ window.location.href;
  const reg = new RegExp(`[?&]${field}=([^&#]*)`, 'i');
  const string = reg.exec(href);
  return string ? string[1] : /* istanbul ignore next */ null;
};

export const updateUserData = ({ data }, dispatch) => {
  dispatch({
    type: ACTIONS.SET_USER_DATA,
    payload: {
      data: {
        ...data,
        isAuthorised: true, // TODO :: ACCESS CHECK API
        userFullName: `${data.FirstName} ${data.Surname}`,
      },
    },
  });
};

export const updateMyProductData = ({ data }, dispatch) => {
  dispatch({
    type: ACTIONS.SET_MY_PRODUCT_DATA,
    payload: {
      data,
    },
  });
};

export const updateOtherProductData = ({ data }, dispatch) => {
  dispatch({
    type: ACTIONS.SET_OTHER_PRODUCT_DATA,
    payload: {
      data,
    },
  });
};

export const logError = /* istanbul ignore next */ (error, file) => {
  const { status } = error.response || 0;
  if (status !== 401) {
    const { message, stack } = error;
    LogError(
      JSON.stringify({
        file,
        message,
        stack,
      }),
    );
  }
};

export const isUrlAbsolute = (url) =>
  url.indexOf('://') > 0 || url.indexOf('//') === 0;

export const getWidget = (type, list) =>
  list.filter((item) => item.type === type);

export const changePasswordErrorHandler = (
  { confirmPassword, oldPassword, newPassword },
  errors,
) => {
  const formErrors = { ...errors };
  formErrors.newPassword = '';
  formErrors.confirmPassword = '';

  if (
    newPassword.length > 0 &&
    !new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$').test(
      newPassword,
    )
  ) {
    formErrors.newPassword = NEW_PASSWORD_ERROR;
  }

  if (
    newPassword.length > 0 &&
    confirmPassword.length > 0 &&
    confirmPassword !== newPassword
  ) {
    formErrors.confirmPassword = CONFIRM_PASSWORD_ERROR;
  }

  if (
    newPassword.length > 0 &&
    oldPassword.length > 0 &&
    newPassword === oldPassword
  ) {
    formErrors.newPassword = OLD_PASSWORD_ERROR;
  }

  return formErrors;
};

export const removeHTMLTags = (str) => str.replace(/<[^>]+>/gi, '');

export const toTitleCase = (data) => {
  let i;
  let j;
  let str;
  str = data.replace(
    /([^\W_]+[^\s-]*) */g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  const lowers = [
    // 'A',
    // 'An',
    // 'The',
    // 'And',
    // 'But',
    // 'Or',
    // 'For',
    // 'Nor',
    // 'As',
    // 'At',
    // 'By',
    // 'For',
    // 'From',
    // 'In',
    // 'Into',
    // 'Near',
    // 'Of',
    // 'On',
    // 'Onto',
    // 'To',
    // 'With',
  ];
  for (i = 0, j = lowers.length; i < j; i += 1) {
    /* istanbul ignore next */
    str = str.replace(new RegExp(`\\s${lowers[i]}\\s`, 'gi'), (txt) =>
      txt.toLowerCase(),
    );
  }

  // Certain words such as initialisms or acronyms should be left uppercase
  const uppers = ['P&C', 'Q&A'];
  for (i = 0, j = uppers.length; i < j; i += 1)
    str = str.replace(
      new RegExp(`\\b${uppers[i]}\\b`, 'gi'),
      uppers[i].toUpperCase(),
    );

  return str;
};

export const genClassName = (id) =>
  id === 2 ? 'marketing-widget' : 'static-widget';
