import { ACTIONS } from '../constants';

export default (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_USER_DATA:
      return {
        ...state,
        ...action.payload.data,
      };
    case ACTIONS.UPDATE_THEME:
      return {
        ...state,
        theme: action.payload.theme,
      };
    default:
      return state;
  }
};
