import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { element, func, shape } from 'prop-types';
import Analytics from './analytics';

const ScrollToTop = ({ history, children }) => {
  useEffect(() => {
    const unlisten = history.listen(
      /* istanbul ignore next */ () => {
        window.scrollTo(0, 0);
        if (history.action === 'PUSH') {
          Analytics.dataLayer({
            event: 'pageLoad',
            product: document.title,
          });
          try {
            const { userID, companyName } = window.analyticsParameters;
            // call hotjar identify api
            if (window.hj && userID && companyName) {
              window.hj('identify', userID, {
                companyName,
              });
            }
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log('hotjar err', err);
          }
        }
      },
    );
    return () => {
      unlisten();
    };
  }, [history]);

  return <>{children}</>;
};

ScrollToTop.propTypes = {
  history: shape({
    push: func.isRequired,
  }).isRequired,
  children: element.isRequired,
};

export default withRouter(ScrollToTop);
