import userReducer from './user';
import myProductReducer from './my-product';
import otherProductReducer from './other-products';

export default /* istanbul ignore next */ (
  { user, myProducts, otherProducts },
  action,
) => ({
  user: userReducer(user, action),
  myProducts: myProductReducer(myProducts, action),
  otherProducts: otherProductReducer(otherProducts, action),
});
