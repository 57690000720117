import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import '@axco/react.library/dist/theme/axco-bootstrap.scss';
import 'react-toastify/dist/ReactToastify.css';
import Redux from '@axco/react.library/dist/shared/stateManagement';
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import './fonts.scss';
import './css/main.scss';
import reducer from './reducers';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

const { StateProvider } = Redux;
const initialState = {
  user: {
    theme: localStorage.getItem('mode') || '',
  },
  myProducts: [],
  otherProducts: [],
};
ReactDOM.render(
  <StateProvider initialState={initialState} reducer={reducer}>
    <Router basename={process.env.PUBLIC_URL}>
      <App />
    </Router>
  </StateProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
