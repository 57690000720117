import HttpService from '@axco/react.library/dist/shared/services/http';

export const getPortalDetails = /* istanbul ignore next */ () => {
  const url = `${process.env.REACT_APP_AXCO_PORTAL_API_ENDPOINT}/Portal/GetPortalDetails`;
  const a = HttpService.get(url);
  return a;
};

export const getMyProducts = /* istanbul ignore next */ () => {
  const url = `${
    process.env.REACT_APP_AXCO_PORTAL_API_ENDPOINT
  }/MyProducts/GetMyProducts?t=${new Date().valueOf()}`;
  const a = HttpService.get(url);
  return a;
};

export const getOtherProducts = /* istanbul ignore next */ (portalId) => {
  const url = `${
    process.env.REACT_APP_AXCO_PORTAL_API_ENDPOINT
  }/OtherProducts?portalId=${portalId}&?t=${new Date().valueOf()}`;
  const a = HttpService.get(url);
  return a;
};

export const getWidgets = /* istanbul ignore next */ (companyId) => {
  const url = `${process.env.REACT_APP_AXCO_PORTAL_API_ENDPOINT}/Widget/GetCompanyWidgets?CompanyId=${companyId}`;
  const a = HttpService.get(url);
  return a;
};

export const getWidgetsByPortalId = /* istanbul ignore next */ (portalId) => {
  const url = `${process.env.REACT_APP_AXCO_PORTAL_API_ENDPOINT}/Widget/GetPortalWidgets?portalId=${portalId}`;
  const a = HttpService.get(url);
  return a;
};

export const getBranding = /* istanbul ignore next */ () => {
  const url = `${process.env.REACT_APP_AXCO_PORTAL_API_ENDPOINT}/Branding/GetUserBranding`;
  const a = HttpService.get(url);
  return a;
};
