import { ACTIONS } from '../constants';

export default (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_OTHER_PRODUCT_DATA:
      return [...state, ...action.payload.data];
    /* istanbul ignore next */
    default:
      /* istanbul ignore next */ return state;
  }
};
